.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
}

.contactText {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0px;
  color: #4a5568;
  text-align: center;
  width: 70%;
}

.contactTitle {
  font-size: 56px;
  font-weight: bold;
  margin-top: 5px;
  color: #2d3748;
  margin-bottom: 25px;
}

.contactInfo,
.mapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #F7F9FA;
  padding: 20px;
}

.contactButtonContainer {
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin-top: 30px;
}

.contactButton {
  display: inline-block;
  padding: 12px 24px;
  color: #fff;
  background-color: #2d3748;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin: 10px 20px;
}

.contactButton:hover {
  color: #e9eff5;
  background-color: #4a5568;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.mapContainer {
  background-color: #e9eff5;
  padding: 20px;
  margin-top: 30px;
}