.homeAboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #e9eff5;
}

.headshot {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}

.title-main {
  font-size: 100px;
  color: #2d3748;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;;
}

.homeAboutContainer p {
  font-size: 24px;
  line-height: 1.6;
  color: #4a5568;
}