.projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}

.projectsSectionTitle {
  font-size: 56px;
  font-weight: bold;
  color: #2d3748;
  margin-top: 0px;
  margin-bottom: 5px;
}

.experiencesSection {
  background-color: #F7F9FA;
  padding: 20px;
  width: 100%;
}

.projectsCardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  background-color: #e9eff5;
}

.projectCard {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  width: calc(50% - 30px);
  height: 500px;
  margin: 15px;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.projectImage {
  width: 100%;
  height: 30%;
  background-size: cover;
  background-position: center;
}

.projectCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  flex-grow: 1;
}

.projectCardContent h3 {
  margin-top: 10px;
  font-size: 32px;
  margin-bottom: 10px;
  flex: 0;
  color: #2d3748
}

.projectCardContent p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  flex: 1;
  color: #4a5568;
}

.projectButton {
  display: inline-block;
  color: #fff;
  background-color: #2d3748;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 5px;
  margin: 10px 20px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.projectButton:hover {
  color: #e9eff5;
  background-color: #4a5568;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.projectCard:hover {
  transform: scale(1.01);
}
